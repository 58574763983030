<template>
  <div>

    <!-- masthead -->

    <header id="masthead" class="site-header clearfix" style="padding: 0">

      <section class="dc-widget-hld ng-scope">

        <section id="dc-widgetholder" class="dc-widget ng-scope">
          <div class="container">
            <ul class="dc-nav-tabs dc-tabs ng-isolate-scope">

              <!--Home link-->
              <li class="menu-item col-sm-6 col-xs-12 ng-scope ng-isolate-scope">
                <router-link class="dc-clear-fix dc-tab-main-link" ref="homeLink" active-class="" to="/">
                  <section class="ng-scope">
                    <h2 class="ng-binding text-uppercase">
                      <i class="icon-MyShipment flaticon1-home"></i> {{ getTranslation('home') }}
                    </h2>
                  </section>
                </router-link>
              </li>

              <!--Book Tab-->
              <li class="menu-item col-sm-6 col-xs-12 ng-scope ng-isolate-scope" :class="{'open': menu === 'book'}">
                <a class="dc-clear-fix dc-tab-main-link" ref="bookLink" @click="setMenu('book')">
                  <section class="ng-scope">
                    <h2 class="ng-binding">
                      <i class="icon-BookAShipment flaticon1-calendar"></i>
                      <div class="text-uppercase">
                        {{ getTranslation('book') }}
                        <i class="icon-DropdownArrowDown mobile-pull-right flaticon1-download"></i>
                        <i class="icon-DropdownUp mobile-pull-right flaticon1-upload"></i>
                        <span class="ng-binding ng-scope">{{ getTranslation('aNewShipment') }}</span>
                      </div>
                    </h2>
                  </section>
                </a>
                <div class="nodisplay">

                  <h3 class="mobile-tablet-heading ng-scope">{{ getTranslation('bookShipment') }}</h3>

                  <!-- ngIf: mobile || tablet -->
                  <ul class="dc-tabs ng-scope ng-isolate-scope">
                    <li :class="{'open': book === 'guided-booking'}" class="dc-guided-booking ng-scope ng-isolate-scope col-xs-6 skip-close-menu">
                      <a class="dc-clear-fix dc-tab-main-link skip-close-menu" @click="book = 'guided-booking'">
                        <section class="ng-scope skip-close-menu">
                          <h3 class="ng-binding skip-close-menu">
                            {{ getTranslation('guidedBooking') }}
                          </h3>
                        </section>
                      </a>
                      <div class="nodisplay skip-close-menu">

                        <ul class="dc-tabs ng-scope ng-isolate-scope skip-close-menu">
                          <li v-for="item in services" v-bind:key="item.id" class="col-xs-4 ng-scope ng-isolate-scope skip-close-menu">
                            <router-link class="dc-clear-fix dc-tab-main-link skip-close-menu" :to="{name:'portal.send-application',params:{type:item.slug}}">
                              <section class="ng-scope skip-close-menu">
                                <h4 class="ng-binding skip-close-menu">
                                  <i class="icon-DoorToDoor skip-close-menu"
                                     :class="{'flaticon1-door':item.slug==='general_cargo','flaticon1-file':item.slug==='dangerous_good',
                                     'flaticon1-file':item.slug==='dangerous_good',
                                     'flaticon1-box':item.slug==='parcel',
                                     'flaticon1-file':item.slug==='dangerous_good',
                                  'flaticon1-paw-print':item.slug==='pets_live',
                                  'flaticon1-flower':item.slug==='perishable',
                                  'flaticon1-capsule':item.slug==='pharma',
                                  'flaticon1-hospital':item.slug==='medical',
                                  'flaticon1-diamond':item.slug==='specialty',
                                  'flaticon-buildings':item.slug==='home_move'}"
                                  ></i> {{ getTranslation(item.slug) }}
                                </h4>
                              </section>
                            </router-link>
                          </li>
                        </ul>
                      </div>
                    </li>

                    <li :class="{'open': book === 'customs-services'}" class="ng-scope ng-isolate-scope col-xs-6 skip-close-menu">
                      <a class="dc-clear-fix dc-tab-main-link skip-close-menu" @click="book = 'customs-services'">
                        <section class="ng-scope skip-close-menu">
                          <h3 class="ng-binding skip-close-menu">
                            {{ getTranslation(customsService.slug) }}
                          </h3>
                        </section>
                      </a>
                      <div class="nodisplay skip-close-menu">
                        <ul class="menu-list skip-close-menu">
                          <li v-for="item in customsDocuments" v-bind:key="item.id" class="skip-close-menu">
                            <a href="javascript:void(0)" class="skip-close-menu">{{ getTranslation(item.slug) }}</a>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>

              <!--Track Shipment Tab-->
              <li class="menu-item col-sm-6 col-xs-12 ng-scope ng-isolate-scope skip-close-menu" :class="{'open': menu === 'track'}">
                <a class="dc-clear-fix dc-tab-main-link skip-close-menu" ref="trackLink" @click="menu = (menu === 'track') ? null : 'track'">
                  <section class="ng-scope">
                    <h2 class="ng-binding">
                      <i class="icon-Track flaticon1-map"></i>
                      <div>
                        {{ getTranslation('Track shipment') }}
                        <i class="icon-DropdownArrowDown mobile-pull-right flaticon1-download"></i>
                        <i class="icon-DropdownUp mobile-pull-right flaticon1-upload"></i>
                        <span class="ng-binding ng-scope">{{ getTranslation('for real-time shipping') }}</span>
                      </div>
                    </h2>
                  </section>
                </a>
                <div class="nodisplay">

                  <h3 class="mobile-tablet-heading ng-scope skip-close-menu">{{ getTranslation('Shipment status') }}</h3>

                  <div class="ng-scope">
                    <form class="tab-form clear-fix ng-pristine ng-isolate-scope ng-invalid ng-invalid-required skip-close-menu" name="tstrackShipmentForm">
                      <div class="dc-mandatory" v-html="getTranslation('mandatoryFieldsAreMarked')" ></div>
                      <span class="info-form ng-binding">{{ getTranslation('Enter your shipment Number') }} <span class="dc-gry-text ng-binding">{{ getTranslation('Example') }}: 12345678</span></span>
                      <span v-if="errMsg" class="dc-mandatory text-danger">{{ getTranslation(errMsg) }}</span>
                      <div class="row">
                        <div class="form-group col-xs-12 col-sm-6 col-md-12 skip-close-menu">
                          <label v-if="!trackAwb" for="trackAwb" class="ng-binding skip-close-menu" >{{ getTranslation('Shipment Number') }}<span class="dc-star">*</span></label>
                          <input v-model="trackAwb" type="text" name="tsaiairwayBillNo" id="trackAwb" class="ng-pristine ng-untouched ng-isolate-scope ng-empty ng-invalid ng-invalid-required skip-close-menu">
                        </div>
                      </div>
                      <button @click.prevent="trackSearch" type="submit" class="btn dc-search-button ng-binding skip-close-menu">{{ getTranslation('search') }}</button>
                    </form>
                  </div>
                </div>
              </li>

              <!--About us link-->
              <li class="menu-item col-sm-6 col-xs-12 ng-scope ng-isolate-scope">
                <a class="dc-clear-fix dc-tab-main-link" href="javascript:void(0)" @click="$router.push({name:'portal.aboutUs'})" ref="aboutLink">
                  <section class="ng-scope">
                    <h2 class="ng-binding">
                      <i class="icon-MyShipment flaticon1-information-sign"></i> {{ getTranslation('About us') }}
                    </h2>
                  </section>
                </a>
              </li>

              <!--Check In Tab-->
              <li class="menu-item col-sm-6 col-xs-12 ng-scope ng-isolate-scope">
                <a class="dc-clear-fix dc-tab-main-link" href="#" ref="adLink">
                  <section class="ng-scope">
                    <h2 class="ng-binding">
                      <i class="icon-MyShipment flaticon1-advertising"></i> {{ getTranslation('Advertising') }}
                    </h2>
                  </section>
                </a>
              </li>
            </ul>
          </div>
        </section>

      </section>

    </header>

    <!-- masthead end -->

    <!--Page Header-->
    <div v-if="$store.getters['heading/getPortalBreadcrumbs'].length > 0" class="page-header title-area">
      <div class="breadcrumb-area">
        <div class="container">
          <div class="row">
            <div class="col-md-8 col-sm-12 col-xs-12 site-breadcrumb">
              <nav class="breadcrumb">
                <span v-for="item in $store.getters['heading/getPortalBreadcrumbs']">
                  <router-link v-if="item.route" :class="{'home': item.route==='/'}" :to="item.route">
                  <span>{{ item.label }}</span>
                </router-link>
                  <i v-if="item.route" class="fa fa-angle-right" aria-hidden="true"></i>
                   <span v-if="!item.route">{{ item.label }}</span>
                </span>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Page Header end-->
  </div>
</template>

<script>
import required from "vuelidate/lib/validators/required";

export default {
  name: "Header",
  data() {
    return {
      book: null,
      trackAwb: '',
      services: [],
      customsService: { id: null },
      customsDocuments: [],
      errMsg: null,
      listenerAttached: false
    }
  },

  created() {
    this.fetchServices().then(() => {
      this.fetchCustomsDocs();
    });
  },

  computed: {
    menu: {
      get() {
        return this.$store.getters['portal/getMenu'];
      },
      set(val) {
        this.$store.commit('portal/SET_MENU', val);
      }
    }
  },
  watch:{
    'menu'(val){
      if(val === 'book')
        this.book='guided-booking';
    }
  },

  mounted() {

    const menuClose = (e) => {
      if ( !e.target.classList.contains('skip-close-menu') ) {
        this.listenerAttached = false;
        this.resetMenu();
        document.removeEventListener('click', menuClose);
      }
    };

    this.$refs.homeLink.onclick = (e) => {
      if ( !this.listenerAttached ) {
        this.listenerAttached = true;
        setTimeout(() => {
          document.addEventListener('click', menuClose);
        });
      }
    };

    this.$refs.bookLink.onclick = (e) => {
      if ( !this.listenerAttached ) {
        this.listenerAttached = true;
        setTimeout(() => {
          document.addEventListener('click', menuClose);
        });
      }
    };

    this.$refs.adLink.onclick = (e) => {
      if ( !this.listenerAttached ) {
        this.listenerAttached = true;
        setTimeout(() => {
          document.addEventListener('click', menuClose);
        });
      }
    };

    this.$refs.trackLink.onclick = (e) => {

      if ( !this.listenerAttached ) {
        this.listenerAttached = true;
        setTimeout(() => {
          document.addEventListener('click', menuClose);
        });
      }

    };

    this.$refs.aboutLink.onclick = (e) => {
      if ( !this.listenerAttached ) {
        this.listenerAttached = true;
        setTimeout(() => {
          document.addEventListener('click', menuClose);
        });
      }
    };


  },

  methods: {

    setMenu(menu) {
      this.menu = menu;
    },

    trackSearch() {

      this.errMsg = null;

      this.$v.trackAwb.$touch();

      if ( this.$v.trackAwb.$invalid )
        return;

      this.axios.post(this.$urls.portal.findApplication.url, { code: this.trackAwb })
          .then(async response => {

            if ( response.data.data.item ) {

              let item = response.data.data.item;

              await this.$store.dispatch('portal/setApp', item);

              this.resetMenu();

              await this.$router.replace({ name: 'portal.tracking' }).catch(() => {
              });

            } else {
              this.errMsg = 'shipmentNotFound';
            }
          }).catch(() => {
        this.errMsg = 'shipmentNotFound';
      });
    },
    resetMenu() {
      this.menu = '';
      this.trackAwb = '';
    },
    fetchServices() {
      return this.axios.get(this.$urls.portal.services.url)
          .then(response => {
            let data = response.data.data;
            this.customsService = data.pop(data);
            this.services = data;
          }).catch(() => {
          });
    },
    fetchCustomsDocs() {
      this.axios.get(this.$urls.portal.customsDocuments.url)
          .then(response => {
            this.customsDocuments = response.data.data;
          }).catch(() => {
      });
    }
  },
  validations: {
    trackAwb: {
      required
    }
  }
}
</script>

<style scoped>
@import "../../assets/portal/menu/flaticon.css";
@import "../../assets/portal/menu/menu.css";
</style>