<template>
  <div>
    <div id="page" ref="page">
      <!-- Preloader-->
      <!--    <div class="preloader"></div>-->

      <TopBar/>
      <Header/>

      <router-view :key="$route.path"></router-view>

      <Footer/>

    </div>

  </div>
</template>

<script>

import TopBar from "@/layouts/portal/TopBar";
import Header from "@/layouts/portal/Header";
import Footer from "@/layouts/portal/Footer";


export default {
  name: "Main",
  components: { Footer, Header, TopBar },

}
</script>


<style>

@import "../../assets/portal/bootstrap.css";
@import "../../assets/portal/animate.css";
@import "../../assets/portal/font-awesome.css";
@import "../../assets/portal/font-awesome/css/all.min.css";
@import "../../assets/portal/frontend.css";
@import "../../assets/portal/style.css";
@import "../../assets/portal/flaticon.css";
@import "../../assets/portal/revolution/settings.css";

@import "../../assets/portal/responsive.css";


</style>
