<template>
  <div>
    <!-- topbar -->
    <div id="fh-header-minimized" class="fh-header-minimized fh-header-v1"></div>

    <div id="topbar" class="topbar">

      <div class="container">

        <div class="row">

          <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 pd-right_zero">


            <div class="topbar-left topbar-widgets text-left">

              <div id="cargo-office-location-widget-2" class="widget cargo-office-location-widget">

                <div class="office-location clearfix">

                  <div id="tab-1" class="tab-content">
                    <ul class="topbar-office ">
                      <li><i class="flaticon-telephone" aria-hidden="true"></i><a :href="getTranslation('callableTel')" style="color: white !important;">{{ getTranslation('topBarPhone') }}</a></li>
                      <li class="timezone-warning-text"><i class="flaticon-alarm-clock-1" aria-hidden="true"></i>
                        {{ getTranslation('System timezone is UTC') }}
                        <span style="font-size: 20px" >{{ time }}</span>
                      </li>
                    </ul>
                  </div>

                </div>

              </div>

            </div>
          </div>

          <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
            <div class="topbar-right topbar-widgets text-right">
              <div class="widget cargohub-social-links-widget">
                <div class="list-social" :class="{'style-2':$store.getters['user/getPortalUserId'],'style-1':!$store.getters['user/getPortalUserId']}">
                  <router-link v-if="!$store.getters['user/getPortalUserId']" :to="{name:'portal.login'}">{{ getTranslation('signIn') }}</router-link>
                  <router-link v-if="!$store.getters['user/getPortalUserId']" :to="{name:'portal.register'}">{{ getTranslation('register') }}</router-link>
                  <a v-if="$store.getters['user/getPortalUserId']" @click="toggleProfile" href="javascript:void(0)" :class="{'active':userDropdown}">
                    <i class="flaticon-people"></i>
                  </a>
                </div>
                <div class="user-dropdown absolute top-0 right-0 bg-white z-30 pt-4 pb-6 pl-4 pr-4 rounded-lg" :class="{'active':userDropdown}">
                  <div v-if="$store.getters['user/getPortalUserId']" class="border-b border-gray-300 pb-5 mb-4 pt-3 text-left">
                    <p class="font-medium text-gray-800">{{ getTranslation('goodAfternoon') }}, {{ $store.getters['user/getPortalUser'].username }}!</p>
                  </div>
                  <div class="text-left">
                    <ul>
                      <li v-if="!hasPortalPermission('admin.home')" class="user-item">
                        <a href="javascript:void(0)" @click="goToProfile">
                          <i class="fas fa-user"></i>
                          <span class="ml-2"> {{ getTranslation('profile') }}</span>
                        </a>
                      </li>
                      <li v-if="hasPortalPermission('admin.home')" class="user-item">
                        <a href="javascript:void(0)" @click="goToProfile">
                          <i class="fas fa-user"></i>
                          <span class="ml-2"> {{ getTranslation('adminka') }}</span>
                        </a>
                      </li>
                      <li v-if="hasPortalPermission('portal.client.profile')" class="user-item">
                        <a href="javascript:void(0)" @click="()=>{ $router.push({name:'portal.client.profile',query:{tab:'company'}}) }">
                          <i class="fa fa-building" aria-hidden="true"></i>
                          <span class="ml-2"> {{ getTranslation('companies') }}</span>
                        </a>
                      </li>
                      <li class="user-item">
                        <a @click="portalLogout" href="javascript:void(0)">
                          <i class="glyphicon glyphicon-log-out"></i>
                          <span class="ml-2"> {{ getTranslation('logout') }}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="widget cargo-search-widget" style="z-index: 9999">
                <div class="sl-nav">
                  <ul>
                    <li :class="{'langOpen':langClicked}" @click="langClicked=!langClicked"  ><b>{{ getTranslation(getLocalStorageLocale()) }}</b> <i class="fa fa-angle-down"  aria-hidden="true"></i>
                      <div class="triangle"></div>
                      <ul >
                        <li v-for="item in $store.getters['language/getLocales']" v-bind:key="item" @click="clickLang(item)">
                          <i class="sl-flag " :class="{'flag-ru':item==='ru','flag-usa':item==='en'}">
                            <div :id="item"></div>
                          </i>
                          <span :class="{'active':item === getLocalStorageLocale()}">{{ getTranslation(item) }}</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <!--                <div class="topbar-search topbar-search-1" :class="{'display-search':showSearch}">-->
                <!--                  <a href="javascript:void(0)" @click="toggleSearch" class="toggle-search" id="toggle-search"><i class="fa fa-search" aria-hidden="true"></i></a>-->
                <!--                  <form class="search-form" action="#">-->
                <!--                    <i class="fa fa-search" aria-hidden="true"></i>-->
                <!--                    <input type="search" class="search-field" placeholder="Search..." value="">-->
                <!--                    <input type="submit" class="search-submit" value="Search">-->
                <!--                  </form>-->
                <!--                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- topbar end -->
  </div>
</template>

<script>

import helpersMixin from "@/views/portal/mixins/helpers";

export default {
  name: "TopBar",
  mixins: [ helpersMixin ],
  data() {
    return {
      showSearch: false,
      userDropdown: false,
      time: '',
      langClicked: false
    }
  },
  created() {
    setInterval(this.getNow, 1000);
  },
  methods: {
    clickLang(lang){
      this.loadLocale(lang).then(()=>{
        this.langClicked=false;
      });
    },
    getNow() {
      this.time = this.$moment.utc().format('HH:mm:ss');
    },
    async goToProfile() {
      let viewer = await this.$store.getters['user/getPortalUser'];

      if ( !viewer )
        return this.$router.push({ path: '/' }).catch(() => {
        });

      if ( viewer.level.id === 5 )
        return this.$router.push({ name: 'portal.client.account' }).catch(() => {
        });

      if ( this.hasPortalPermission('admin.home') )
        return await this.$router.push({ name: 'admin.login' }).then(() => {
          window.location.reload();
        });

    },
    toggleSearch() {
      this.showSearch = !this.showSearch
    },
    toggleProfile() {
      this.userDropdown = !this.userDropdown
    }
  }
}
</script>

<style scoped>
.timezone-warning-text {
  color: red !important;
  font-weight: bolder !important;
}
</style>