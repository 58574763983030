<template>
  <div>
    <!--footer sec-->
    <div class="footer-widgets widgets-area">
      <div class="contact-widget">
        <div class="container">
          <div class="row">
            <div class="contact col-md-3 col-xs-12 col-sm-12">
              <router-link to="/"  class="footer-logo"><img style="width: 130px" src="@/assets/portal/images/logo-darkblue.jpg" alt="Footer Logo"></router-link>
            </div>
            <div class="contact col-md-3 col-xs-12 col-sm-12"><i class="flaticon-signs"></i>
              <p> {{ getTranslation('address') }}: Cargo Jet</p>
              <h4 v-html="getTranslation('cargoJetAddress')" ></h4>
            </div>
            <div class="contact col-md-3 col-xs-12 col-sm-12"><i class="flaticon-phone-call "></i>
              <p>{{ getTranslation('contacts') }} :</p>
              <h4><a :href="getTranslation('callableTel')" style="color: white !important;">{{ getTranslation('topBarPhone') }}</a></h4>
            </div>
            <div class="contact col-md-3 col-xs-12 col-sm-12"><i class="flaticon-clock-1"></i>
              <p>{{ getTranslation('workingHours') }} :</p>
              <h4>{{ getTranslation('cargoJetWorkHours') }}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-sidebars">
        <div class="container">
          <div class="row">
            <div class="footer-sidebar footer-1 col-xs-12 col-sm-6 col-md-4">
              <div class="widget widget_text">
                <h4 class="widget-title">{{ getTranslation('About us') }}</h4>
                <div class="textwidget">
                  <p v-html="getTranslation('aboutUsFooter')"></p>
                </div>
              </div>
            </div>
            <div class="footer-sidebar footer-2 col-xs-12 col-sm-6 col-md-4">
              <div class="widget widget_nav_menu">
                <h4 class="widget-title">{{ getTranslation('Useful links') }}</h4>
                <div class="menu-service-menu-container">
                  <ul class="menu">
                    <li><router-link to="/">{{ getTranslation('home') }}</router-link></li>
                    <li><router-link :to="{name:'portal.aboutUs'}">{{ getTranslation('About us') }}</router-link></li>
                    <li><a href="javascript:void(0)">{{ getTranslation('Advertising') }}</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="footer-sidebar footer-3 col-xs-12 col-sm-6 col-md-4">
              <div class="widget latest-project-widget">
                <h4 class="widget-title">{{ getTranslation('Photo gallery') }}</h4>
                <div class="latest-project-list clearfix">
                  <div class="latest-project clearfix">
                    <div class="fp-widget-thumb">
                      <a class="widget-thumb" href="javascript:void(0)">
                        <i class="fa fa-link" aria-hidden="true"></i>
                        <img src="@/assets/portal/images/projects/fg1.jpg" alt="">
                      </a>
                    </div>
                  </div>
                  <div class="latest-project clearfix">
                    <div class="fp-widget-thumb">
                      <a class="widget-thumb" href="javascript:void(0)">
                        <i class="fa fa-link" aria-hidden="true"></i>
                        <img src="@/assets/portal/images/projects/fg2.jpg" alt="">
                      </a>
                    </div>
                  </div>
                  <div class="latest-project clearfix">
                    <div class="fp-widget-thumb">
                      <a class="widget-thumb" href="javascript:void(0)">
                        <i class="fa fa-link" aria-hidden="true"></i>
                        <img src="@/assets/portal/images/projects/fg3.jpg" alt="">
                      </a>
                    </div>
                  </div>
                  <div class="latest-project clearfix">
                    <div class="fp-widget-thumb">
                      <a class="widget-thumb" href="javascript:void(0)">
                        <i class="fa fa-link" aria-hidden="true"></i>
                        <img src="@/assets/portal/images/projects/fg4.jpg" alt="">
                      </a>
                    </div>
                  </div>
                  <div class="latest-project clearfix">
                    <div class="fp-widget-thumb">
                      <a class="widget-thumb" href="javascript:void(0)" >
                        <i class="fa fa-link" aria-hidden="true"></i>
                        <img src="@/assets/portal/images/projects/fg5.jpg" alt="">
                      </a>
                    </div>
                  </div>
                  <div class="latest-project clearfix">
                    <div class="fp-widget-thumb">
                      <a class="widget-thumb" href="javascript:void(0)" >
                        <i class="fa fa-link" aria-hidden="true"></i>
                        <img src="@/assets/portal/images/projects/fg6.jpg" alt="">
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--footer sec end-->

    <!--copyright sec-->
    <footer class="site-footer">
      <div class="container">
        <div class="row">
          <div class="footer-copyright col-md-6 col-sm-12 col-sx-12">
            <div class="site-info">Copyright @{{ year }} <a href="javascript:void(0)">Cargo Jet</a>, All Rights Reserved</div>
          </div>
        </div>
      </div>
    </footer>
    <!--copyright sec end-->
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: "Footer",
  data() {
    return {
      year: moment().format('YYYY'),
    }
  }
}
</script>

<style scoped>

</style>